<template>
  <form class="columns is-multiline guardian-service-form" @submit.prevent="submit">

    <div class="column is-12">
      <h2 class="is-size-3">Optional Services:</h2>
      <PerfectScrollbarWrapper>
      <b-table
          class="margin-top is-fullwidth"
          :data="services"
          :striped="true"
          :hoverable="true"
          :bordered="true"
          :scrollable="false"
      >
        <template #empty>
          <div class="has-text-centered">No services</div>
        </template>
        <b-table-column
            v-slot="props"
            label="ID"
            field="id"
            sortable
            width="40"
            numeric
        >{{ props.row.id }}
        </b-table-column>
        <b-table-column
            v-slot="props"
            label="Name"
            field="name"
            sortable

        >{{ props.row.name }}
        </b-table-column>
        <b-table-column
            v-slot="props"
            label="Cost"
            field="cost"
            sortable
            numeric
        >{{ props.row.cost }}
        </b-table-column>
           <b-table-column
            v-slot="props"
            label="Billing Period"
            field="billing_period"
            sortable
            numeric
        >{{ props.row.billing_period }}
        </b-table-column>
        <b-table-column
            v-slot="props"
            label="Notice Period (days)"
            field="notice_period"
            sortable
            numeric
        >{{ props.row.notice_period }}
        </b-table-column>

        <b-table-column
            v-slot="props"
            label="Downloads"
        >
          <b-field v-if="props.row.media.length>0">
            <b-taglist> <a v-for="file in props.row.media" download :href="file.temporary_url" target="_blank"
                           :key="file.id" class="tag">{{ file.caption }}</a></b-taglist>

          </b-field>
        </b-table-column>
        <b-table-column
            v-slot="props"
            label="Select"
            width="40"
            centered
        >
          <b-field>
            <b-checkbox v-model="application.services.optionalServices"
                        :native-value="props.row.id">
            </b-checkbox>
          </b-field>
        </b-table-column>
      </b-table>
      </PerfectScrollbarWrapper>

    </div>
    <div class="column is-12">
      <b-field position="is-centered" group-multiline grouped>
        <b-field>
          <b-button
              outlined
              @click.prevent="save()"
              type="is-primary">Save and Continue Later
          </b-button>
        </b-field>
        <b-field>
          <b-button
              native-type="submit"
              type="is-primary">Next
          </b-button>
        </b-field>
      </b-field>
    </div>
    <b-loading :is-full-page="false" :active.sync="loading"
               :can-cancel="false"></b-loading>
  </form>
</template>

<script>

import {mapState} from 'vuex'
import Package from "@/models/Package";
import Service from "@/models/Service";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";

export default {
  name: 'ServicesForm',
  components: { PerfectScrollbarWrapper },
  data() {
    return {
      loading: true,
      package_id: null,
    }
  },
  computed: {
    ...mapState('application', ['application']),
    packages() {
      return Package.query().where('is_open',1).with('services').with('phases').with('services.media').get()
    },
    services() {
      return Service.query().where('in_package', null).with('phases').where('is_open', 1).with('media').get()
    },
  },
  mounted() {
    Service.deleteAll()
    Package.deleteAll()
    Promise.all([Package.FetchAll({page: 1, limit: 999}, {
      campus_id: this.application.general.campus_id,
      year: this.application.general.year,
      phase_id: this.application.general.phase_id
    }, ['services', 'services.media', 'phases'])
      , Service.FetchAll({page: 1, limit: 999}, {
        campus_id: this.application.general.campus_id,
        year: this.application.general.year,
        phase_id: this.application.general.phase_id,
        without_phases: 1,
      }, ['media', 'phases'])
    ]).catch(err => {
      this.handleError(err)
    }).finally(() => {
      this.loading = false
    })
  },
  methods: {

    save() {
      this.$emit('save', 'services')
    },
    submit() {
      this.application.services.package_services = Package.query().whereId(this.application.services.package_id).with('services').first().service_ids
      this.$emit('submit')
    }
  }
}
</script>
