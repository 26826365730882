<template>
  <form class="columns is-multiline" @submit.prevent="submit">
    <div class="column is-12">
      <h3 class="is-size-4">Payment Responsibility:</h3>
      <div class="columns is-centered mt-4">
        <div class="column is-12">
          <b-field grouped group-multiline>
            <b-field>
              <b-radio required v-model="responsibilityRadio"
                       name="name"
                       native-value="first">
                First parent/guardian
              </b-radio>
            </b-field>

            <b-field>
              <b-radio required v-model="responsibilityRadio"
                       name="name"
                       native-value="second">
                Second parent/guardian

              </b-radio>
            </b-field>
            <b-field>
              <b-radio required v-model="responsibilityRadio"
                       name="name"
                       native-value="both">
                Both parents/guardians
              </b-radio>
            </b-field>
            <b-field>
              <b-radio required v-model="responsibilityRadio"
                       name="name"
                       native-value="other"
              >
                Other relationship
              </b-radio>
            </b-field>
          </b-field>
        </div>

      </div>
    </div>
    <div v-if="application.payment_responsibility.type ==='other'" class="column is-12 ">
      <div class="columns is-multiline">
        <div class="column is-4">
          <b-field expanded :label="`What is your relationship to the ${$tc('learner',1)}`">
            <b-input
                v-model="otherData.relationship"
                required
                autocomplete="off"
                placeholder="Relationship"
            />

          </b-field>
        </div>
        <div class="column is-4">
          <b-field expanded label="First name">
            <b-input
                v-model="otherData.first_name"
                required
                autocomplete="off"
                placeholder="First name"
            />

          </b-field>
        </div>
        <div class="column is-4">
          <b-field expanded label="Last name">
            <b-input
                v-model="otherData.last_name"
                required
                autocomplete="off"
                placeholder="Last name"
            />

          </b-field>
        </div>
        <div class="column is-4">
          <b-field expanded label="Contact Number">
            <b-input
                v-model="otherData.contact"
                required
                autocomplete="off"
                placeholder="021 555 5555"
            />

          </b-field>
        </div>
        <div class="column is-4">
          <b-field expanded label="ID Number">

            <b-input
                v-model="otherData.id_number"
                required

                autocomplete="off"
                placeholder="ID number"
            />

          </b-field>
        </div>
      </div>
    </div>
    <div class="column is-12">
      <b-field position="is-centered" group-multiline grouped>

        <b-field>
          <b-button
              outlined
              @click.prevent="save()"
              type="is-primary">Save and Continue Later
          </b-button>
        </b-field>
        <b-field>
          <b-button
              native-type="submit"
              type="is-primary">Next
          </b-button>
        </b-field>
      </b-field>
    </div>
  </form>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */


import {mapState} from 'vuex'
import Lookup from "@/models/Lookup";

export default {
  name: 'PaymentForm',
  data() {
    return {
      otherData: {
        relationship: '',
        first_name: '',
        last_name: '',
        contact: '',
        id_number: '',
      },
    }
  },
  computed: {
    responsibilityRadio: {
      get() {
        return this.application.payment_responsibility.type
      },
      set(newValue) {
        switch (newValue) {
          case 'first':
            this.application.payment_responsibility.type = 'first'
            this.application.general.payment_responsibility = this.application.guardian1.user.first_name + ' ' + this.application.guardian1.user.last_name
            break;
          case 'second':
            this.application.payment_responsibility.type = 'second'

            this.application.general.payment_responsibility = this.application.guardian2.user.first_name + ' ' + this.application.guardian2.user.last_name
            break;
          case 'both':
            this.application.payment_responsibility.type = 'both'

            this.application.general.payment_responsibility = this.application.guardian1.user.first_name + ' ' + this.application.guardian1.user.last_name + ',' + this.application.guardian2.user.first_name + ' ' + this.application.guardian2.user.last_name
            break;
          case 'other':
            this.application.payment_responsibility.type = 'other'

        }
      }
    },

    genders() {
      return Lookup.query().where('lookup_type_id', 1).orderBy('name').get()
    },
    ...mapState('application', ['application']),

  }
  ,
  methods: {
    remove_sibling(index) {
      this.$buefy.dialog.confirm({
        title: "Deleting sibling",
        message:
            "Are you sure you want to <b>delete</b> this? This action cannot be undone.",
        confirmText: "Delete Sibling",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
            (this.application.student.profile.siblings = this.application.student.profile.siblings.filter(
                (item, place) => place !== index
            )),
      });
    }
    ,
    add_sibling() {
      this.application.student.profile.siblings.push({
        name: null,
        age: null,
        gender: null,
        current_school: null,
      });
    }
    ,
    save() {
      if (this.application.payment_responsibility.type === 'other') {
        this.application.general.payment_responsibility = `Relationship: ${this.otherData.relationship};Name: ${this.otherData.first_name} ${this.otherData.last_name};Contact: ${this.otherData.contact};ID Number: ${this.otherData.id_number}`
      }
      this.$emit('save', 'payment_responsibility')
    }
    ,
    submit() {
      if (this.application.payment_responsibility.type === 'other') {
        this.application.general.payment_responsibility = `Relationship: ${this.otherData.relationship};Name: ${this.otherData.first_name} ${this.otherData.last_name};Contact: ${this.otherData.contact};ID Number: ${this.otherData.id_number}`
      }
      this.$emit('submit')
    }
  }
}
</script>
