<template>
  <form class="columns is-multiline" @submit.prevent="submit">
    <div class="column is-12">

      <p>Family history, previous illnesses, physical or learning difficulties, past or present medical
        treatments including therapy (occupational or psychological), should be written below:
      </p>

      <div class="columns is-centered is-multiline mt-4">
        <div class="column is-12">

          <b-field label="Other information">
            <b-input
                autocomplete="off"
                type="textarea"

                v-model="application.student.profile.medical_information.additional_information"
                placeholder="Information"
            />
          </b-field>
        </div>


      </div>
    </div>
    <div class="column is-12">
      <b-field position="is-centered" group-multiline grouped>

        <b-field>
          <b-button
              outlined
              @click.prevent="save()"
              type="is-primary">Save and Continue Later
          </b-button>
        </b-field>
        <b-field>
          <b-button
              native-type="submit"
              type="is-primary">Next
          </b-button>
        </b-field>
      </b-field>
    </div>
  </form>
</template>

<script>

import {mapState} from 'vuex'

export default {
  name: 'OtherForm',
  data() {
    return {}
  },
  computed: {

    ...mapState('application', ['application']),

  },
  methods: {

    save() {
      this.$emit('save', 'other_info')
    },
    submit() {
      this.$emit('submit')
    }
  }
}
</script>
