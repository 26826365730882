<template>
  <form class="columns is-multiline" @submit.prevent="submit">
    <div class="column is-12">
      <b-field group-multiline grouped>
        <b-field expanded label="Title">
          <b-select
              expanded
              v-model="application[objectKey].user.title"
              placeholder="Select a title"
          >
            <option value="Mr">Mr</option>
            <option value="Mrs">Mrs</option>
            <option value="Ms">Ms</option>
            <option value="Dr">Dr</option>
          </b-select>
        </b-field>
        <b-field expanded label="First Name">
          <b-field>
            <b-input
                autocomplete="off"
                :icon="$tc('icons.user')"
                v-model="application[objectKey].user.first_name"
                placeholder="First Name"
                name="first_name"
                required
            />
          </b-field>
        </b-field>
        <b-field expanded label="Last Name">
          <b-field>
            <b-input
                autocomplete="off"
                :icon="$tc('icons.user')"
                v-model="application[objectKey].user.last_name"
                placeholder="Last Name"
                name="last_name"
                required
            />
          </b-field>
        </b-field>
      </b-field>

      <b-field label="Email Address">
        <b-field>
          <b-input
              :disabled="objectKey==='guardian1'"
              autocomplete="off"
              icon="email"
              type="email"
              v-model="application[objectKey].user.email"
              placeholder="E-mail"
              name="email"
              required
          />
        </b-field>
      </b-field>
    </div>
    <div class="column is-12">
      <div class="columns is-multiline">

        <div class="column is-4">
          <b-field expanded label="Type of ID">
            <b-select expanded required v-model="application[objectKey].profile.identification_type_id"
                      placeholder="ID type">
              <option
                  v-for="type in id_types" :key="type.id" :value="type.id">{{ type.name }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field expanded label="ID number">

                <b-input
                    required

                    autocomplete="off"

                    v-model="application[objectKey].profile.id_number"
                    placeholder="ID number"
                />

          </b-field>
        </div>
        <div class="column is-4">
          <ValidationObserver ref="observer">
            <b-field expanded label="Country of issue">
              <ValidationProvider rules="autocomplete:@confirm" v-slot="{ errors }">
                <b-autocomplete
                    :data="countries"
                    autocomplete="off"
                    v-model="country"
                    placeholder="Country"
                    name="country"
                    required
                    field="name"
                    :clear-on-select="false"
                    @select="option=>selectCountry(option)"
                >
                </b-autocomplete>
                <span class="has-text-weight-bold has-text-danger">{{ errors[0] }}</span>

              </ValidationProvider>
            </b-field>

            <b-field class="is-hidden" label="Confirm password" horizontal>
              <ValidationProvider rules="required" name="confirm" v-slot="{ errors }">
                <b-input v-model="application[objectKey].profile.country_of_issue_id"/>
                <span>{{ errors[0] }}</span>
              </ValidationProvider>
            </b-field>
          </ValidationObserver>
        </div>

        <div class="column is-4">
          <b-field expanded label="Home Number">
            <vue-tel-input v-if="application[objectKey].user.primary_contact_number.length>0"
                           :inputOptions="{required:false}" ref="primary_contact_number" :defaultCount="+27"
                           :autoFormat="false" :enabledCountryCode="true"
                           :mode="'international'"
                           :invalidMsg="'Please enter a valid phone number'"
                           v-model="application[objectKey].user.primary_contact_number"></vue-tel-input>
            <b-input v-else
                     @input="keepTyping($event,'primary_contact_number')"

                     autocomplete="off"
                     v-model="application[objectKey].user.primary_contact_number"
                     name="phone"
                     type="tel"
                     validation-message="Please enter a valid phone number"
                     pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"
                     :icon="$tc('icons.phone')"
                     placeholder="072 555 5555"
                     expanded
            />
          </b-field>

        </div>
        <div class="column is-4">
          <b-field expanded :label="$tc('Cellphone', 1) + ' Number'">
            <vue-tel-input v-if="application[objectKey].user.cellphone_number.length>0" :inputOptions="{required:false}" ref="cellphone_number" :defaultCount="+27" :autoFormat="false" :enabledCountryCode="true"
                           :mode="'international'"
                           :invalidMsg="'Please enter a valid phone number'"
                           v-model="application[objectKey].user.cellphone_number"></vue-tel-input>
            <b-input v-else
                     @input="keepTyping($event,'cellphone_number')"

                     autocomplete="off"
                     v-model="application[objectKey].user.cellphone_number"
                     name="phone"
                     type="tel"
                     validation-message="Please enter a valid phone number"
                     pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"
                     :icon="$tc('icons.phone')"
                     placeholder="072 555 5555"
                     expanded
            />
          </b-field>

        </div>
        <!--        <div class="column is-4">-->
        <!--          <b-field expanded label="Gender">-->
        <!--            <b-select-->
        <!--                expanded v-model="application[objectKey].profile.gender_id" placeholder="Gender">-->
        <!--              <option v-for="gender in genders" :key="gender.id" :value="gender.id">{{ gender.name }}</option>-->
        <!--            </b-select>-->
        <!--          </b-field>-->
        <!--        </div>-->

      </div>
    </div>
    <div class="column is-12">
      <hr>

      <h3 class="is-size-4">Home Address:</h3>

      <div class="columns is-centered is-multiline mt-4">
        <div class="column is-4">
          <b-field label="Building Number">
            <b-input
                autocomplete="off"
                :icon="$tc(`icons.map-marker`)"
                v-model="application[objectKey].profile.physical_address.line_2"
                placeholder="10 Fancy Mansions"
            />
          </b-field>
        </div>
        <div class="column is-4">

          <b-field label="Street Address">
            <b-input
                autocomplete="off"
                :icon="$tc(`icons.map-marker`)"
                required
                v-model="application[objectKey].profile.physical_address.line_1"
                placeholder="15 Main Street"
            />
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="Suburb">
            <b-input
                autocomplete="off"
                required
                :icon="$tc(`icons.map-marker`)"
                v-model="application[objectKey].profile.physical_address.suburb"
                placeholder="Constantia"
            />
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="City">
            <b-input
                autocomplete="off"
                required
                :icon="$tc(`icons.map-marker`)"
                v-model="application[objectKey].profile.physical_address.city"
                placeholder="Cape Town"
            />
          </b-field>
        </div>

        <div class="column is-4">
          <b-field label="Province">
            <b-input
                autocomplete="off"
                :icon="$tc(`icons.map-marker`)"
                required
                v-model="application[objectKey].profile.physical_address.province"
                placeholder="Western Cape"
            />
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="Postal Code">
            <b-input
                autocomplete="off"
                :icon="$tc(`icons.map-marker`)"
                required
                v-model="application[objectKey].profile.physical_address.postal_code"
                placeholder="5432"
            />
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="Country">
            <b-input
                autocomplete="off"
                :icon="$tc(`icons.map-marker`)"
                required
                v-model="application[objectKey].profile.physical_address.country"
                placeholder="South Africa"
            />
          </b-field>
        </div>
      </div>
    </div>
    <div class="column is-12">
      <hr>
      <h3 class="is-size-4">Employment Information:</h3>

      <div class="columns is-centered mt-4 is-multiline">
        <div class="column is-4">
          <b-field expanded label="Occupation">
            <b-input required v-model="application[objectKey].profile.employment_information.type_of_work"
                     placeholder="Occupation"></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field expanded label="Employer">
            <b-input required v-model="application[objectKey].profile.employment_information.employer"
                     placeholder="Employer"></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field expanded label="Employer's contact number">
            <b-input required v-model="application[objectKey].profile.employment_information.contact_number"
                     placeholder="Employer's contact number"></b-input>
          </b-field>
        </div>
      </div>
    </div>
    <div class="column is-12">
      <hr>

      <h3 class="is-size-4">Work Address:</h3>

      <div class="columns is-centered is-multiline mt-4">
        <div class="column is-4">
          <b-field label="Building Number">
            <b-input
                autocomplete="off"
                :icon="$tc(`icons.map-marker`)"
                v-model="application[objectKey].profile.alternative_address.line_2"
                placeholder="10 Fancy Mansions"
            />
          </b-field>
        </div>
        <div class="column is-4">

          <b-field label="Street Address">
            <b-input
                autocomplete="off"
                :icon="$tc(`icons.map-marker`)"
                required
                v-model="application[objectKey].profile.alternative_address.line_1"
                placeholder="15 Main Street"
            />
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="Suburb">
            <b-input
                autocomplete="off"
                required
                :icon="$tc(`icons.map-marker`)"
                v-model="application[objectKey].profile.alternative_address.suburb"
                placeholder="Constantia"
            />
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="City">
            <b-input
                autocomplete="off"
                required
                :icon="$tc(`icons.map-marker`)"
                v-model="application[objectKey].profile.alternative_address.city"
                placeholder="Cape Town"
            />
          </b-field>
        </div>

        <div class="column is-4">
          <b-field label="Province">
            <b-input
                autocomplete="off"
                :icon="$tc(`icons.map-marker`)"
                required
                v-model="application[objectKey].profile.alternative_address.province"
                placeholder="Western Cape"
            />
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="Postal Code">
            <b-input
                autocomplete="off"
                :icon="$tc(`icons.map-marker`)"
                required
                v-model="application[objectKey].profile.alternative_address.postal_code"
                placeholder="5432"
            />
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="Country">
            <b-input
                autocomplete="off"
                :icon="$tc(`icons.map-marker`)"
                required
                v-model="application[objectKey].profile.alternative_address.country"
                placeholder="South Africa"
            />
          </b-field>
        </div>
      </div>
    </div>
    <div class="column is-12">
      <b-field position="is-centered" group-multiline grouped>

        <b-field v-if="canSkip">
          <b-button
              outlined
              @click.prevent="skip()"
              type="is-primary">Skip
          </b-button>
        </b-field>
        <b-field>
          <b-button
              outlined
              @click.prevent="save()"
              type="is-primary">Save and Continue Later
          </b-button>
        </b-field>
        <b-field>
          <b-button
              native-type="submit"
              type="is-primary">Next
          </b-button>
        </b-field>
      </b-field>
    </div>
  </form>
</template>

<script>

import {mapState} from 'vuex'
import Lookup from "@/models/Lookup";
import {format} from "date-fns";
import {ValidationProvider, ValidationObserver, extend} from "vee-validate";
import {required} from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required",
});

extend("autocomplete", {
  params: ["target"],
  validate(value, {target}) {
    return target !== null;
  },
  message: "Please select an item from the list",
});
extend("id", (idVal) => {
  const checkDigit = parseInt(idVal.charAt(idVal.length - 1));
  let odd = 0;
  let even = "";
  let evenResult = 0;
  let result;
  for (let c = 1; c <= idVal.length; c++) {
    // noinspection EqualityComparisonWithCoercionJS
    if (c % 2 == 0) {
      even += idVal.charAt(c - 1);
    } else {
      // noinspection EqualityComparisonWithCoercionJS
      if (c == idVal.length) {
        // noinspection UnnecessaryContinueJS
        continue;
      } else {
        odd = parseInt(odd) + parseInt(idVal.charAt(c - 1));
      }
    }
  }
  even = Number(even) * 2;
  even = even.toString();
  for (let r = 1; r <= even.length; r++) {
    evenResult = parseInt(evenResult) + parseInt(even.charAt(r - 1));
  }
  result = parseInt(odd) + parseInt(evenResult);
  result = result.toString();
  result = 10 - parseInt(result.charAt(result.length - 1));
  result = result.toString();
  if (result.length > 1) {
    result = result.charAt(result.length - 1);
  }
  // noinspection EqualityComparisonWithCoercionJS
  if (parseInt(result) != checkDigit) {
    return "Please enter a valid SA ID number";
  } else {
    return true;
  }
});

export default {
  name: 'GuardianForm',
  data() {
    return {
      language: '',
      country: '',

    }
  },
  components: {
    ValidationProvider, ValidationObserver
  },
  computed: {
    ...mapState('application', ['application']),
    date_of_birth: {
      get() {
        return new Date(this.application[this.objectKey].profile.date_of_birth)
      }, set(newValue) {
        this.application[this.objectKey].profile.date_of_birth = format(newValue, 'yyyy-MM-dd')
      },
    },
    grades() {
      return Lookup.query().where('lookup_type_id', 13).orderBy('order').get()
    },
    genders() {
      return Lookup.query().where('lookup_type_id', 1).orderBy('name').get()
    }, religions() {
      return Lookup.query().where('lookup_type_id', 8).orderBy('name').get()
    }, ethnicities() {
      return Lookup.query().where('lookup_type_id', 2).orderBy('name').get()
    }, id_types() {
      return Lookup.query().where('lookup_type_id', 3).get()
    }, languages() {
      return Lookup.query().where('lookup_type_id', 5).where(language => {
        return this.language ? language.name.toLowerCase().includes(this.language.toLowerCase()) : true
      }).orderBy('name').get()
    }, countries() {
      return Lookup.query().where('lookup_type_id', 4).where(country => {
        return this.country ? country.name.toLowerCase().includes(this.country.toLowerCase()) : true
      }).orderBy('name').get()
    },
  },
  props: {
    canSkip: {
      type: Boolean, default() {
        return false
      }
    },
    objectKey: {
      type: String,
      required: true
    }
  },
  methods: {
    keepTyping(event, ref) {
      this.$nextTick(() => {
        this.$refs[ref].focus()
      })
    },
    selectLanguage(language) {
      this.language = language.name
      this.application[this.objectKey].profile.language_id = language.id
    }, selectCountry(country) {
      this.country = country.name
      this.application[this.objectKey].profile.country_of_issue_id = country.id
    },
    save() {
      this.$emit('save', this.objectKey)
    },
    skip() {
      this.$emit('skip')
    },
    submit() {
      this.$refs.observer.validate().then((valid) => {
        if (!valid) {
          return;
        }
        this.application[this.objectKey].user.primary_contact_number=this.application[this.objectKey].user.primary_contact_number.length>0?this.$refs.primary_contact_number.phoneObject.number:""
        this.application[this.objectKey].user.cellphone_number=this.application[this.objectKey].user.cellphone_number.length>0?this.$refs.cellphone_number.phoneObject.number:""
        this.$emit('submit')
      })

    },

  }
}
</script>
