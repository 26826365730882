<template>
  <form class="columns is-multiline" @submit.prevent="submit">
    <div class="column is-12">


      <h3 class="is-size-4">Medical Information:</h3>

      <div class="columns is-centered is-multiline mt-4">
        <div class="column is-4">

          <b-field label="Doctor / Paediatrician name">
            <b-input
                autocomplete="off"
                :required="has_medical_info"

                v-model="application.student.profile.medical_information.doctor_name"
                placeholder="Doctor / Paediatrician name"
            />
          </b-field>
        </div>
        <div class="column is-4">

          <b-field label="Contact number">
            <b-input
                autocomplete="off"
                :required="has_medical_info"

                type="tel"
                validation-message="Please enter a valid phone number"
                pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"
                v-model="application.student.profile.medical_information.contact_number"
                placeholder="Contact number"
            />
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="Has medical aid">
            <b-select expanded v-model="application.student.profile.medical_information.has_medical_aid">
              <option :value="true">Yes</option>
              <option :value="false">No</option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-4">

          <b-field label="Medical aid provider">
            <b-input
                autocomplete="off"
                :required="application.student.profile.medical_information.has_medical_aid"
                :disabled="!application.student.profile.medical_information.has_medical_aid"
                v-model="application.student.profile.medical_information.medical_aid_provider"
                placeholder="Medical aid provider"
            />
          </b-field>
        </div>

        <div class="column is-4">

          <b-field label="Medical aid number">
            <b-input
                :required="application.student.profile.medical_information.has_medical_aid"
                :disabled="!application.student.profile.medical_information.has_medical_aid"
                autocomplete="off"
                v-model="application.student.profile.medical_information.medical_aid_number"
                placeholder="Medical aid number"
            />
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="Allergies">
            <b-input type="textarea" autocomplete="off" required
                     v-model="application.student.profile.medical_information.allergies" placeholder="Allergies"/>
          </b-field>
        </div>

      </div>
    </div>
    <div class="column is-12">
      <hr>

      <h3 class="is-size-4">Emergency Contact:</h3>

      <div class="columns is-centered is-multiline mt-4">
        <div class="column is-4">

          <b-field label="First name">
            <b-input
                autocomplete="off"
                required
                v-model="application.student.profile.emergency_contact.first_name"
                placeholder="First name"
            />
          </b-field>
        </div>
        <div class="column is-4">

          <b-field label="Last name">
            <b-input
                autocomplete="off"

                v-model="application.student.profile.emergency_contact.last_name"
                placeholder="Last name"
            />
          </b-field>
        </div>
        <div class="column is-4">

          <b-field label="Contact Number">
            <b-input
                autocomplete="off"
                required
                type="tel"
                validation-message="Please enter a valid phone number"
                pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"
                v-model="application.student.profile.emergency_contact.contact_number"
                placeholder="Contact Number"
            />
          </b-field>
        </div>
        <div class="column is-4">

          <b-field :label="`Relationship to ${$tc('learner',1)}`">
            <b-input
                autocomplete="off"
                required
                v-model="application.student.profile.emergency_contact.relationship"
                :placeholder="`Relationship to ${$tc('learner',1)}`"
            />
          </b-field>
        </div>


      </div>
    </div>
    <div class="column is-12">
      <b-field position="is-centered" group-multiline grouped>

        <b-field>
          <b-button
              outlined
              @click.prevent="save()"
              type="is-primary">Save and Continue Later
          </b-button>
        </b-field>
        <b-field>
          <b-button
              native-type="submit"
              type="is-primary">Next
          </b-button>
        </b-field>
      </b-field>
    </div>
  </form>
</template>

<script>

import {mapState} from 'vuex'

export default {
  name: 'EmerMedicalForm',
  data() {
    return {}
  },
  computed: {

    ...mapState('application', ['application']),

  },
  methods: {

    save() {
      this.$emit('save', 'emermedical')
    },
    submit() {
      this.$emit('submit')
    }
  }
}
</script>
