<template>
  <form class="columns is-multiline" @submit.prevent="submit">
    <div class="column is-12">
      <h3 class="is-size-4">Siblings:</h3>
      <div class="columns is-centered mt-4">
        <b-field>
          <div class="table-container">
            <table class="table is-bordered is-striped">
              <thead>
              <tr class="table-input">
                <th>Name</th>
                <th>Age</th>
                <th>Gender</th>
                <th>
                  Current School
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(sibling,index) in application.student.profile.siblings" :key="index">
                <td>
                  <b-field>
                    <b-input
                        autocomplete="off"
                        placeholder="Bob"
                        v-model="sibling.name"
                        name="name"
                    />
                  </b-field>
                </td>
                <td>
                  <b-field>
                    <b-input
                        type="number"
                        autocomplete="off"
                        placeholder="12"
                        v-model="sibling.age"
                        name="age"
                    />
                  </b-field>
                </td>
                <td>
                  <b-field>
                    <b-select v-model="sibling.gender" placeholder="Gender">
                      <option v-for="gender in genders" :key="gender.id" :value="gender.name">{{ gender.name }}</option>
                    </b-select>
                  </b-field>
                </td>
                <td>
                  <b-field>
                    <b-input
                        autocomplete="off"
                        placeholder="School name"
                        v-model="sibling.current_school"
                        name="current_school"
                    />
                  </b-field>
                </td>


                <td>
                  <b-button
                      @click.prevent="remove_sibling(index)"
                      icon-right="delete"
                      type="is-ghost"
                      class="has-text-danger"                  />
                </td>

              </tr>
              </tbody>
            </table>
            <b-field>
              <b-button
                  type="is-primary"
                  @click.prevent="add_sibling"
              >Add sibling
              </b-button>
            </b-field>
          </div>
        </b-field>

      </div>
    </div>
    <div class="column is-12">
      <b-field position="is-centered" group-multiline grouped>
        <b-field>
          <b-button
              outlined
              @click.prevent="save()"
              type="is-primary">Save and Continue Later
          </b-button>
        </b-field>
        <b-field>
          <b-button
              native-type="submit"
              type="is-primary">Next
          </b-button>
        </b-field>
      </b-field>
    </div>
  </form>
</template>

<script>

import {mapState} from 'vuex'
import Lookup from "@/models/Lookup";

export default {
  name: 'SiblingsForm',
  data() {
    return {}
  },
  computed: {
    genders() {
      return Lookup.query().where('lookup_type_id', 1).orderBy('name').get()
    },
    ...mapState('application', ['application']),

  },
  methods: {
    remove_sibling(index) {
      this.$buefy.dialog.confirm({
        title: "Deleting sibling",
        message:
            "Are you sure you want to <b>delete</b> this? This action cannot be undone.",
        confirmText: "Delete Sibling",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
            (this.application.student.profile.siblings = this.application.student.profile.siblings.filter(
                (item, place) => place !== index
            )),
      });
    },
    add_sibling() {
      this.application.student.profile.siblings.push({
        name: null,
        age: null,
        gender: null,
        current_school: null,
      });
    },
    save() {
      this.$emit('save', 'siblings')
    },
    submit() {
      this.$emit('submit')
    }
  }
}
</script>
