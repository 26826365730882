<template>
  <form class="columns is-multiline" @submit.prevent="submit">
    <div class="column is-12">
      <hr>
      <div class="columns is-centered mt-4">
        <div class="column is-12">
          <b-field style="max-width: 100%" grouped group-multiline>
            <b-field style="max-width: 100%">
              <b-radio required v-model="consent"
                       name="field1"
                       native-value="first">
                First parent/guardian
              </b-radio>
            </b-field>

            <b-field style="max-width: 100%">
              <b-radio required v-model="consent"
                       name="field1"
                       native-value="second">
                Second parent/guardian

              </b-radio>
            </b-field>
            <b-field style="max-width: 100%">
              <b-radio required v-model="consent"
                       name="field1"
                       native-value="both">
                Both parents/guardians
              </b-radio>
            </b-field>
            <b-field style="max-width: 100%">
              <b-radio required v-model="consent"
                       name="field1"
                       native-value="other"
              >
                Payment responsibility nominees
              </b-radio>
            </b-field>
          </b-field>
          <p>Hereby declare that both Parents/Guardians whose details appear on the above application form consent to
            the completion and submission of said application to Generation Schools. </p>
        </div>

      </div>
    </div>
    <div class="column is-12">
      <hr>
      <div class="columns is-centered mt-4">
        <div class="column is-12">
          <b-field grouped group-multiline style="max-width: 100%">
            <b-field style="max-width: 100%">
              <b-radio required v-model="picture"
                       name="picture"
                       native-value="first-agree">
                First parent/guardian - Agree
              </b-radio>
            </b-field>
            <b-field style="max-width: 100%">
              <b-radio required v-model="picture"
                       name="picture"
                       native-value="second-agree">
                Second parent/guardian - Agree
              </b-radio>
            </b-field>
            <b-field style="max-width: 100%">
              <b-radio required v-model="picture"
                       name="picture"
                       native-value="both-agree">
                Both parents/guardians - Agree
              </b-radio>
            </b-field>
            <b-field style="max-width: 100%">
              <b-radio required v-model="picture"
                       name="picture"
                       native-value="other-agree"
              >
                Payment responsibility nominees - Agree
              </b-radio>
            </b-field>
            <b-field style="max-width: 100%">
              <b-radio required v-model="picture"
                       name="picture"
                       native-value="first-disagree">
                First parent/guardian - Disagree
              </b-radio>
            </b-field>
            <b-field style="max-width: 100%">
              <b-radio required v-model="picture"
                       name="picture"
                       native-value="second-disagree">
                Second parent/guardian - Disagree

              </b-radio>
            </b-field>
            <b-field style="max-width: 100%">
              <b-radio required v-model="picture"
                       name="picture"
                       native-value="both-disagree">
                Both parents/guardians - Disagree
              </b-radio>
            </b-field>
            <b-field style="max-width: 100%">
              <b-radio required v-model="picture"
                       name="picture"
                       native-value="other-disagree"
              >
                Payment responsibility nominees - Disagree
              </b-radio>
            </b-field>
          </b-field>
          <p>Hereby agree to consent to Generation Schools using visual images of my child for the purposes of the
            school.</p>
        </div>

      </div>
    </div>
    <div class="column is-12">
      <hr>
      <div class="columns is-centered mt-4">
        <div class="column is-12">
          <b-field group-multiline grouped style="max-width: 100%">
            <b-field style="max-width: 100%">
              <b-radio required v-model="apply"
                       name="field2"
                       native-value="first">
                First parent/guardian
              </b-radio>
            </b-field>
            <b-field style="max-width: 100%">
              <b-radio required v-model="apply"
                       name="field2"
                       native-value="second">
                Second parent/guardian

              </b-radio>
            </b-field>
            <b-field style="max-width: 100%">
              <b-radio required v-model="apply"
                       name="field2"
                       native-value="both">
                Both parents/guardians
              </b-radio>
            </b-field>
            <b-field style="max-width: 100%">
              <b-radio required v-model="apply"
                       name="field2"
                       native-value="other"
              >
                Payment responsibility nominees
              </b-radio>
            </b-field>
          </b-field>
          <p>Hereby voluntarily provide consent for a reference check to be carried out on parent(s), guardian(s) and/or
            payment responsibility nominee(s) in my schools’ application form. I/we accept that such check does not
            infringe any of my/our fundamental rights, and I/we accept that the reference check is part of the
            registration / renewal process in terms of the Act. If there are multiple parent(s), guardian(s) and/or
            payment responsibility nominees in my schools application form, consent on their behalf is required before
            ticking the box.</p>
        </div>

      </div>
    </div>
    <div class="column is-12">
      <div class="columns is-centered mt-4">
        <div class="column is-12">
          <b-field style="max-width: 100%" group-multiline grouped>
            <b-field style="max-width: 100%">
              <b-radio required v-model="terms"
                       name="name"
                       native-value="first">
                First parent/guardian
              </b-radio>
            </b-field>
            <b-field style="max-width: 100%">
              <b-radio required v-model="terms"
                       name="name"
                       native-value="second">
                Second parent/guardian

              </b-radio>
            </b-field>
            <b-field style="max-width: 100%">
              <b-radio required v-model="terms"
                       name="name"
                       native-value="both">
                Both parents/guardians
              </b-radio>
            </b-field>
            <b-field style="max-width: 100%">
              <b-radio required v-model="terms"
                       name="name"
                       native-value="other"
              >
                Payment responsibility nominees
              </b-radio>
            </b-field>
          </b-field>
          <p>Hereby accept and agree to Generation Schools Terms & Conditions
          </p>
        </div>
      </div>
      <hr>
      <b-button type="is-link" tag="a" v-if="application.general.programme_id===null"
                href="https://generation-sis-assets.s3-eu-west-1.amazonaws.com/downloads/Generation-Education-Pty-Ltd-Indemnity-All-in-one-latest-2021.pdf"
                target="_blank">Download Terms and Conditions
      </b-button>
      <b-button type="is-link" tag="a" v-else
                href="https://generation-sis-assets.s3.eu-west-1.amazonaws.com/downloads/Si%CC%81+Terms.pdf"
                target="_blank">Download Terms of Registration
      </b-button>
    </div>
    <div class="column is-12">
      <b-field position="is-centered" grouped>
        <b-field>
          <b-button
              native-type="submit"
              type="is-primary">Next
          </b-button>
        </b-field>

      </b-field>
    </div>
  </form>
</template>

<script>


import {mapState} from 'vuex'

export default {
  name: 'ConsentForm',
  data() {
    return {
      terms: null,
      consent: null,
      picture: null,
      apply: null
    }
  },
  watch: {
    picture(newVal) {
      if (newVal !== null) {
        if (newVal.includes('disagree')) {
          if (this.application.student.profile.consent.some(item => item.type === 'picture')) {
            this.application.student.profile.consent[this.application.student.profile.consent.findIndex(i => i.type === 'picture')].is_given = false
          } else {
            this.application.student.profile.consent.push({type: 'picture', is_given: false})
          }
        } else {
          if (this.application.student.profile.consent.some(item => item.type === 'picture')) {
            this.application.student.profile.consent[this.application.student.profile.consent.findIndex(i => i.type === 'picture')].is_given = true
          } else {
            this.application.student.profile.consent.push({type: 'picture', is_given: true})
          }
        }
      }
    }, terms(newVal) {
      if (newVal !== null) {
        if (newVal.includes('disagree')) {
          if (this.application.student.profile.consent.some(item => item.type === 'terms')) {
            this.application.student.profile.consent[this.application.student.profile.consent.findIndex(i => i.type === 'terms')].is_given = false
          } else {
            this.application.student.profile.consent.push({type: 'terms', is_given: false})
          }
        } else {
          if (this.application.student.profile.consent.some(item => item.type === 'terms')) {
            this.application.student.profile.consent[this.application.student.profile.consent.findIndex(i => i.type === 'terms')].is_given = true
          } else {
            this.application.student.profile.consent.push({type: 'terms', is_given: true})
          }
        }
      }
    }, apply(newVal) {
      if (newVal !== null) {
        if (newVal.includes('disagree')) {
          if (this.application.student.profile.consent.some(item => item.type === 'apply')) {
            this.application.student.profile.consent[this.application.student.profile.consent.findIndex(i => i.type === 'apply')].is_given = false
          } else {
            this.application.student.profile.consent.push({type: 'apply', is_given: false})
          }
        } else {
          if (this.application.student.profile.consent.some(item => item.type === 'apply')) {
            this.application.student.profile.consent[this.application.student.profile.consent.findIndex(i => i.type === 'apply')].is_given = true
          } else {
            this.application.student.profile.consent.push({type: 'apply', is_given: true})
          }
        }
      }
    }, consent(newVal) {
      if (newVal !== null) {
        if (newVal.includes('disagree')) {
          if (this.application.student.profile.consent.some(item => item.type === 'consent')) {
            this.application.student.profile.consent[this.application.student.profile.consent.findIndex(i => i.type === 'consent')].is_given = false
          } else {
            this.application.student.profile.consent.push({type: 'consent', is_given: false})
          }
        } else {
          if (this.application.student.profile.consent.some(item => item.type === 'consent')) {
            this.application.student.profile.consent[this.application.student.profile.consent.findIndex(i => i.type === 'consent')].is_given = true
          } else {
            this.application.student.profile.consent.push({type: 'consent', is_given: true})
          }
        }
      }
    },
  },
  computed: {


    ...mapState('application', ['application']),

  }
  ,
  methods: {


    submit() {

      this.$emit('submit')
    }
  }
}
</script>
