<template>
  <form class="columns is-multiline" @submit.prevent="submit">
    <div class="column is-12">
      <b-field group-multiline grouped>
        <b-field expanded label="First Name">
          <b-field>
            <b-input
                autocomplete="off"
                :icon="$tc('icons.user')"
                v-model="application.student.user.first_name"
                placeholder="First Name"
                name="first_name"
                required
            />
          </b-field>
        </b-field>
        <b-field expanded label="Last Name">
          <b-field>
            <b-input
                autocomplete="off"
                :icon="$tc('icons.user')"
                v-model="application.student.user.last_name"
                placeholder="Last Name"
                name="last_name"
                required
            />
          </b-field>
        </b-field>
      </b-field>

      <b-field grouped group-multiline>
        <b-field :message="`This email address is for the new ${$tc('learner',1)} and cannot be the same as yours. If the ${$tc('learner',1)} does not have a personal email please tick the \'Has No Email\' box.`" expanded label="Email Address">
          <b-input
              autocomplete="off"
              icon="email" expanded
              type="email"
              :disabled="application.student.user.no_email==1"
              v-model="application.student.user.email"
              placeholder="E-mail"
              name="email"
              :required="!application.student.user.no_email==1"
          />
        </b-field>
        <b-field label="Has No Email" class="has-text-centered">
          <b-checkbox @input="application.student.user.email=null" v-model="application.student.user.no_email"
                      :true-value="1" :false-value="0"></b-checkbox>
        </b-field>
      </b-field>

    </div>
    <div class="column is-12">
      <div class="columns is-multiline">
        <div class="column is-4">
          <b-field expanded label="Date of birth">
            <b-datepicker
                required
                :years-range="[-100,100]"
                locale="en-ZA"
                v-model="date_of_birth"
                placeholder="Click to select..."
                :icon="$tc('icons.calendar')"
                trap-focus>
            </b-datepicker>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field expanded label="Type of ID">
            <b-select expanded required v-model="application.student.profile.identification_type_id"
                      placeholder="ID type">
              <option
                  v-for="type in id_types" :key="type.id" :value="type.id">{{ type.name }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field expanded label="ID number">

                <b-input
                    required

                    autocomplete="off"

                    v-model="application.student.profile.id_number"
                    placeholder="ID number"
                />

          </b-field>
        </div>
        <div class="column is-4">
          <ValidationObserver ref="observer">
            <b-field expanded label="Country of issue">
              <ValidationProvider rules="autocomplete:@confirm" v-slot="{ errors }">
                <b-autocomplete
                    :data="countries"
                    autocomplete="off"
                    v-model="country"
                    placeholder="Country"
                    name="country"
                    required
                    field="name"
                    :clear-on-select="false"
                    @select="option=>selectCountry(option)"
                >
                </b-autocomplete>
                <span class="has-text-weight-bold has-text-danger">{{ errors[0] }}</span>

              </ValidationProvider>
            </b-field>

            <b-field class="is-hidden" label="Confirm password" horizontal>
              <ValidationProvider rules="required" name="confirm" v-slot="{ errors }">
                <b-input v-model="application.student.profile.country_of_issue_id"/>
                <span>{{ errors[0] }}</span>
              </ValidationProvider>
            </b-field>
          </ValidationObserver>
        </div>

        <div class="column is-4">
          <b-field expanded label="Gender">
            <b-select
                expanded v-model="application.student.profile.gender_id" placeholder="Gender">
              <option v-for="gender in genders" :key="gender.id" :value="gender.id">{{ gender.name }}</option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field expanded label="Dominant hand">
            <b-select required  expanded v-model="application.student.profile.dominant_hand"
                      placeholder="Dominant hand">
              <option value="Right-hand">Right-hand</option>
              <option value="Left-hand">Left-hand</option>
              <option value="Ambidextrous">Ambidextrous</option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field expanded label="Ethnicity">
            <b-select required expanded v-model="application.student.profile.ethnicity_id" placeholder="Ethnicity">
              <option v-for="ethnicity in ethnicities" :key="ethnicity.id" :value="ethnicity.id">{{
                  ethnicity.name
                }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-4">
          <ValidationObserver ref="observer">
            <b-field expanded label="Home language">
              <ValidationProvider rules="autocomplete:@confirm" v-slot="{ errors }">
                <b-autocomplete
                    :data="languages"
                    autocomplete="off"
                    v-model="language"
                    placeholder="Language"
                    name="language"
                    required
                    field="name"
                    :clear-on-select="false"
                    @select="option=>selectLanguage(option)"
                >
                </b-autocomplete>
                <span class="has-text-weight-bold has-text-danger">{{ errors[0] }}</span>

              </ValidationProvider>
            </b-field>

            <b-field class="is-hidden" label="Confirm password" horizontal>
              <ValidationProvider rules="required" name="confirm" v-slot="{ errors }">
                <b-input v-model="application.student.profile.language_id"/>
                <span>{{ errors[0] }}</span>
              </ValidationProvider>
            </b-field>
          </ValidationObserver>
        </div>
        <div class="column is-4">
          <b-field expanded label="Religion">
            <b-select required expanded v-model="application.student.profile.religion_id" placeholder="Religion">
              <option v-for="religion in religions" :key="religion.id" :value="religion.id">{{
                  religion.name
                }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field expanded label="Current School">
            <b-input required v-model="application.student.profile.school_attended.name"/>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field expanded label="Current Grade">
            <b-select required expanded v-model="application.student.profile.grade_id"
                      placeholder="Grade">
              <option v-for="grade in grades" :key="grade.id" :value="grade.id">{{
                  grade.name
                }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>
    </div>
    <div class="column is-12"><h3 class="is-size-4">Home Address:</h3>

      <div class="columns is-centered is-multiline mt-4">
        <div class="column is-4">
          <b-field label="Building Number">
            <b-input
                autocomplete="off"
                :icon="$tc(`icons.map-marker`)"
                v-model="application.student.profile.physical_address.line_2"
                placeholder="10 Fancy Mansions"
            />
          </b-field>
        </div>
        <div class="column is-4">

          <b-field label="Street Address">
            <b-input
                autocomplete="off"
                :icon="$tc(`icons.map-marker`)"
                required
                v-model="application.student.profile.physical_address.line_1"
                placeholder="15 Main Street"
            />
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="Suburb">
            <b-input
                autocomplete="off"
                required
                :icon="$tc(`icons.map-marker`)"
                v-model="application.student.profile.physical_address.suburb"
                placeholder="Constantia"
            />
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="City">
            <b-input
                autocomplete="off"
                required
                :icon="$tc(`icons.map-marker`)"
                v-model="application.student.profile.physical_address.city"
                placeholder="Cape Town"
            />
          </b-field>
        </div>

        <div class="column is-4">
          <b-field label="Province">
            <b-input
                autocomplete="off"
                :icon="$tc(`icons.map-marker`)"
                required
                v-model="application.student.profile.physical_address.province"
                placeholder="Western Cape"
            />
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="Postal Code">
            <b-input
                autocomplete="off"
                :icon="$tc(`icons.map-marker`)"
                required
                v-model="application.student.profile.physical_address.postal_code"
                placeholder="5432"
            />
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="Country">
            <b-input
                autocomplete="off"
                :icon="$tc(`icons.map-marker`)"
                required
                v-model="application.student.profile.physical_address.country"
                placeholder="South Africa"
            />
          </b-field>
        </div>
      </div>
    </div>
    <div class="column is-12">
      <b-field position="is-centered" group-multiline grouped>

        <b-field>
          <b-button
              outlined
              @click.prevent="save()"
              type="is-primary">Save and Continue Later
          </b-button>
        </b-field>
        <b-field>
          <b-button
              native-type="submit"
              type="is-primary">Next
          </b-button>
        </b-field>
      </b-field>
    </div>
  </form>
</template>

<script>

import {mapState} from 'vuex'
import Lookup from "@/models/Lookup";
import {format} from "date-fns";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {required} from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required",
});

extend("autocomplete", {
  params: ["target"],
  validate(value, {target}) {
    return target !== null;
  },
  message: "Please select an item from the list",
});
extend("id", (idVal) => {
  const checkDigit = parseInt(idVal.charAt(idVal.length - 1));
  let odd = 0;
  let even = "";
  let evenResult = 0;
  let result;
  for (let c = 1; c <= idVal.length; c++) {
    // noinspection EqualityComparisonWithCoercionJS
    if (c % 2 == 0) {
      even += idVal.charAt(c - 1);
    } else {
      // noinspection EqualityComparisonWithCoercionJS
      if (c == idVal.length) {
        // noinspection UnnecessaryContinueJS
        continue;
      } else {
        odd = parseInt(odd) + parseInt(idVal.charAt(c - 1));
      }
    }
  }
  even = Number(even) * 2;
  even = even.toString();
  for (let r = 1; r <= even.length; r++) {
    evenResult = parseInt(evenResult) + parseInt(even.charAt(r - 1));
  }
  result = parseInt(odd) + parseInt(evenResult);
  result = result.toString();
  result = 10 - parseInt(result.charAt(result.length - 1));
  result = result.toString();
  if (result.length > 1) {
    result = result.charAt(result.length - 1);
  }
  // noinspection EqualityComparisonWithCoercionJS
  if (parseInt(result) != checkDigit) {
    return "Please enter a valid SA ID number";
  } else {
    return true;
  }
});

export default {
  name: 'LearnerForm',
  data() {
    return {
      language: '',
      country: '',

    }
  },
  components: {
    ValidationProvider, ValidationObserver
  },
  mounted() {
    if (this.application.student.profile.language_id) {
      this.language = Lookup.query().where('lookup_type_id', 5).where('id', this.application.student.profile.language_id).first().name
    }
    if (this.application.student.profile.country_of_issue_id) {
      this.country = Lookup.query().where('lookup_type_id', 4).where('id', this.application.student.profile.country_of_issue_id).first().name
    }
  },
  computed: {
    ...mapState('application', ['application']),
    date_of_birth: {
      get() {
        return new Date(this.application.student.profile.date_of_birth)
      }, set(newValue) {
        this.application.student.profile.date_of_birth = format(newValue, 'yyyy-MM-dd')
      },
    },
    grades() {
      return Lookup.query().where('lookup_type_id', 13).orderBy('order').get()
    },
    genders() {
      return Lookup.query().where('lookup_type_id', 1).orderBy('name').get()
    }, religions() {
      return Lookup.query().where('lookup_type_id', 8).orderBy('name').get()
    }, ethnicities() {
      return Lookup.query().where('lookup_type_id', 2).orderBy('name').get()
    }, id_types() {
      return Lookup.query().where('lookup_type_id', 3).get()
    }, languages() {
      return Lookup.query().where('lookup_type_id', 5).where(language => {
        return this.language ? language.name.toLowerCase().includes(this.language.toLowerCase()) : true
      }).orderBy('name').get()
    }, countries() {
      return Lookup.query().where('lookup_type_id', 4).where(country => {
        return this.country ? country.name.toLowerCase().includes(this.country.toLowerCase()) : true
      }).orderBy('name').get()
    },
  },

  methods: {
    selectLanguage(language) {
      this.language = language.name
      this.application.student.profile.language_id = language.id
    }, selectCountry(country) {
      this.country = country.name
      this.application.student.profile.country_of_issue_id = country.id
    },
    save() {
      this.$emit('save', 'student')
    },
    submit() {
      this.$refs.observer.validate().then((valid) => {
        if (!valid) {
          return;
        }
        this.$emit('submit')
      })

    },

  }
}
</script>
