<template>
  <form class="columns is-multiline" @submit.prevent="submit">
    <div class="column is-4">
      <b-field label="Starting Month">
        <b-select required expanded placeholder="Starting Month" v-model="application.general.month">
          <option :value="1">January</option>
          <option :value="2">February</option>
          <option :value="3">March</option>
          <option :value="4">April</option>
          <option :value="5">May</option>
          <option :value="6">June</option>
          <option :value="7">July</option>
          <option :value="8">August</option>
          <option :value="9">September</option>
          <option :value="10">October</option>
          <option :value="11">November</option>
          <option :value="12">December</option>
        </b-select>
      </b-field>
    </div>
    <div class="column is-4">
      <b-field label="Starting Year">
        <b-select required expanded placeholder="Starting Year" v-model="application.general.year">
          <option
              v-for="year of $store.getters['dates/application_years']"
              :key="year + 'year'"
              :value="year"
          >{{ year }}
          </option>
        </b-select>
      </b-field>
    </div>
    <div class="column is-12">
      <b-field label="Application Type" expanded>
        <b-select :disabled="!application.general.year" required expanded placeholder="Application Type"
                  v-model="application_type">
          <option
              :value="0"
          >General Course
          </option>
          <option
              :value="1"
          >Alternative Qualifications Programme
          </option>
        </b-select>
      </b-field>

    </div>
    <div class="column is-12">
      <b-field grouped group-multiline label="Campus Options">
        <b-field expanded>
          <b-select :disabled="!application.general.year" required expanded placeholder="Campus" v-model="campus_id">
            <option
                v-for="campus in campuses"
                :key="campus.id"
                :value="campus.id"
            >{{ campus.name }}
            </option>
          </b-select>
        </b-field>
        <b-field v-if="application_type===0" expanded>
          <b-select :disabled="!application.general.campus_id" required expanded placeholder="Phase"
                    v-model="application.general.phase_id">
            <option
                v-for="phase in phases"
                :key="phase.id"
                :value="phase.id"
            >{{ phase.long_name }}
            </option>
          </b-select>
        </b-field>
        <b-field v-if="application_type===0" expanded>
          <b-select :disabled="!application.general.phase_id" required expanded placeholder="Stage"
                    v-model="application.general.stage_id">
            <option
                v-for="stage in stages"
                :key="stage.id"
                :value="stage.id"
            >{{ stage.long_name }}
            </option>
          </b-select>
        </b-field>


      </b-field>

    </div>
    <div v-if="application_type===1" class="column is-12">
      <b-field label="Programme" expanded>
        <b-select :disabled="!application.general.campus_id" required expanded placeholder="Programme"
                  :loading="loadingProgrammes"
                  v-model="application.general.programme_id">
          <option
              v-for="programme in programmes"
              :key="programme.id"
              :value="programme.id"
          >{{ programme.provider_code }} {{ programme.name }}
          </option>
        </b-select>
      </b-field>
<!--      <div v-if="application.general.programme_id!==null" class="column is-12">-->
<!--        <b-field label="Costs"></b-field>-->

<!--        <table class="table is-bordered">-->
<!--          <tbody>-->
<!--          <tr>-->
<!--            <td>Registration Cost</td>-->
<!--            <td>R{{ selectedProgramme.registration_cost }}</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td>Placement Cost</td>-->
<!--            <td>R{{ selectedProgramme.placement_cost }}</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td>Monthly Cost (Campus)</td>-->
<!--            <td>R{{ selectedProgramme.campus_cost_per_month }}</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td>Annual Cost (Campus)</td>-->
<!--            <td>R{{ selectedProgramme.campus_cost_per_annum }}</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td>Monthly Cost (Remote)</td>-->
<!--            <td>R{{ selectedProgramme.distance_cost_per_month }}</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td>Annual Cost (Remote)</td>-->
<!--            <td>R{{ selectedProgramme.distance_cost_per_annum }}</td>-->
<!--          </tr>-->
<!--          </tbody>-->
<!--        </table>-->
<!--        <b-field label="Subjects"></b-field>-->
<!--        <b-table-->
<!--            class="margin-top"-->
<!--            :data="selectedProgrammeSubjects"-->
<!--            :striped="true"-->
<!--            :hoverable="true"-->
<!--            :bordered="true"-->
<!--            narrowed-->
<!--        >-->
<!--          <template #empty>-->
<!--            <div class="has-text-centered">No Subjects</div>-->
<!--          </template>-->
<!--          <b-table-column label="Provider Code"-->
<!--                          v-slot="props"-->
<!--                          field="provider_code">-->
<!--            {{ props.row.provider_code }}-->
<!--          </b-table-column>-->
<!--          <b-table-column-->
<!--              label="Name"-->
<!--              v-slot="props"-->
<!--              field="long_name"-->


<!--          >{{ props.row.name }}-->
<!--          </b-table-column>-->


<!--        </b-table>-->


<!--      </div>-->
    </div>
    <div v-if="application.general.programme_id!==null" class="column is-12">
      <b-field expanded group-multiline grouped label="Programme Options">
        <b-field v-if="application_type===1" expanded>
          <b-select :disabled="!application.general.campus_id" required expanded placeholder="Delivery Type"
                    v-model="application.general.programme_delivery_type">
            <option
                :value="'remote'"
            >Remote
            </option>
            <option
                :value="'campus'"
            >On Campus
            </option>
          </b-select>
        </b-field>
        <b-field v-if="application_type===1" expanded>
          <b-select :disabled="!application.general.campus_id" required expanded placeholder="Payment Type"
                    v-model="application.general.programme_payment_type">
            <option
                :value="'monthly'"
            >Monthly
            </option>
            <option
                :value="'annual'"
            >Annual
            </option>
          </b-select>
        </b-field>
      </b-field>
      <b-field expanded label="Student Devices" grouped group-multiline>
        <b-field expanded label="Does the student have their own device?">
          <b-switch v-model="application.general.has_device" :true-value="1" :false-value="0">
            {{ application.general.has_device === 1 ? 'Yes' : 'No' }}
          </b-switch>
        </b-field>
        <b-field expanded label="Would you like to purchase a device?">
          <b-switch v-model="application.general.purchase_device" :true-value="1" :false-value="0">
            {{ application.general.purchase_device === 1 ? 'Yes' : 'No' }}
          </b-switch>
        </b-field>
      </b-field>
    </div>
    <div v-if="application_type!==1" class="column is-12">
      <b-field grouped group-multiline>
        <b-field expanded label="Package">
          <b-select :disabled="!application.general.campus_id" expanded required
                    v-model="application.services.package_id">
            <option v-for="servicePackage in packages" :key="servicePackage.id" :value="servicePackage.id">
              {{ servicePackage.name }}
            </option>
          </b-select>
        </b-field>
        <!--        <b-field expanded label="Day length">-->
        <!--          <b-select expanded required v-model="application.student.profile.day_length">-->
        <!--            <option :value="3">Half Day excl. lunch</option>-->
        <!--            <option :value="1">-->
        <!--              Half Day incl. lunch-->
        <!--            </option>-->
        <!--            <option :value="2">-->
        <!--              Full Day-->
        <!--            </option>-->
        <!--          </b-select>-->
        <!--        </b-field>-->
        <!--        <b-field expanded label="Lunch option">-->
        <!--          <b-select expanded v-model="application.student.profile.lunch_option">-->
        <!--            <option :value="null">None</option>-->
        <!--            <option value="Lunch">-->
        <!--              Lunch Normal-->
        <!--            </option>-->
        <!--            <option value="Lunch vegan">-->
        <!--              Lunch Vegan-->
        <!--            </option>-->
        <!--            <option value="Lunch vegetarian">-->
        <!--              Lunch Vegetarian-->
        <!--            </option>-->
        <!--            <option value="Lunch gluten-free">-->
        <!--              Lunch Gluten-free-->
        <!--            </option>-->
        <!--            <option value="Lunch dairy-free">Lunch Dairy-free</option>-->
        <!--          </b-select>-->
        <!--        </b-field>-->
      </b-field>


    </div>
    <div v-if="selectedPackage" class="column is-12">

      <b-field label="Package Description"></b-field>
      <div class="pt-3 pb-3 ck-content" v-html="selectedPackage.description"></div>


    </div>
    <div class="column is-12">
      <b-field group-multiline position="is-centered" grouped>

        <b-field>
          <b-button
              outlined
              @click.prevent="save()"
              type="is-primary">Save and Continue Later
          </b-button>
        </b-field>
        <b-field>
          <b-button
              native-type="submit"
              type="is-primary">Next
          </b-button>
        </b-field>
      </b-field>
    </div>
  </form>
</template>

<script>
import Campus from "@/models/Campus";
import Phase from "@/models/Phase";
import Stage from "@/models/Stage";
import {mapState} from 'vuex'
import Package from "@/models/Package";
import Programme from "@/models/Programme";
import Subject from "@/models/Subject";

export default {
  name: 'GeneralForm',
  data() {
    return {
      application_type: 0,
      loadingProgrammes: false
    }
  },
  watch: {
    application_type(newVal) {
      if (newVal === 1) {
        this.application.services.package_id = null
        this.application.general.phase_id = null
        this.application.general.stage_id = null
        this.loadingProgrammes = true
        Programme.FetchAll({page: 1, limit: 999}, ['subjects']).then(() => {
          this.loadingProgrammes = false
        })
        Package.deleteAll()
        Package.FetchAll({page: 1, limit: 999}, {
          year: this.application.general.year,
          campus_id: this.application.general.campus_id,
        })

      }
      if (newVal === 0) {
        this.application.services.package_id = null
        this.application.general.programme_id = null
        this.application.general.programme_delivery_type = null
        this.application.general.programme_payment_type = null
      }
    },
    'application.general.year'() {
      Package.deleteAll()
      Package.FetchAll({page: 1, limit: 999}, {year: this.application.general.year})
    },
    'application.general.phase_id'() {
      Package.deleteAll()
      Package.FetchAll({page: 1, limit: 999}, {
        year: this.application.general.year,
        campus_id: this.application.general.campus_id,
        phase_id: this.application.general.phase_id
      })
    },
  },
  mounted() {
    Package.deleteAll()
    if (this.application.general.programme_id !== null) {
      this.application_type = 1
    }
    if (this.application.general.phase_id) {
      Package.FetchAll({page: 1, limit: 999}, {
        year: this.application.general.year,
        campus_id: this.application.general.campus_id,
        phase_id: this.application.general.phase_id
      })
    }
  },
  computed: {
    selectedProgramme() {
      if (this.application.general.programme_id !== null) {
        return Programme.query().find(this.application.general.programme_id)
      }
      return null
    },
    selectedProgrammeSubjects() {
      if (this.application.general.programme_id !== null) {
        return Subject.query().whereIdIn(Programme.find(this.application.general.programme_id)?.subject_ids
        )
            .where('campus_id', this.application.general.campus_id)
            .get()
      }
      return []
    },
    selectedPackage() {
      if (this.application.services.package_id) {
        return Package.query().where('id', this.application.services.package_id).first()
      }
      return null
    },
    packages() {
      return Package.query().where('campus_id', this.application.general.campus_id).where('is_open', 1).where('year', this.application.general.year).get()
    }, programmes() {
      return Programme.query().with('subjects').get()
    },
    phase_id: {
      set(newValue) {
        this.application.general.stage_id = null
        this.application.general.phase_id = newValue

      }, get() {
        return this.application.general.phase_id
      }
    },
    campus_id: {
      set(newValue) {
        this.application.general.phase_id = null
        this.application.general.stage_id = null
        this.application.general.campus_id = newValue

      }, get() {
        return this.application.general.campus_id
      }
    },
    ...mapState('application', ['application']),
    campuses() {
      return Campus.query().with('phases').get().filter(campus => {
        return campus.phases.length > 0
      });
    },
    phases() {
      return Phase.query().where('campus_id', this.application.general.campus_id).where('year', this.application.general.year).orderBy('ordinality').get()
    },
    stages() {
      return Stage.query().where('campus_id', this.application.general.campus_id).where('phase_id', this.application.general.phase_id).orderBy('ordinality').get()
    }
  },
  methods: {
    save() {
      this.$emit('save', 'general')
    },
    submit() {
      this.$emit('submit')
    }
  }
}
</script>
