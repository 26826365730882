<template>
  <div v-if="loaded">
    <CampusHeader
        :can-select="false"
        :header-text="headerText"
        :hide-tabs="true"
        :manage-campuses="false"
        :show-campus="false"
        :show-mobile="false"
        @change-tab="changeTab"
    />
    <section class="section pt-4 box">
      <b-tabs expanded multiline  v-model="activeTab" :class="{'hidden-tabs':tabs.length<2}" :destroy-on-hide="true" @input="changeTab">
        <b-tab-item v-if="useTab('General')" label="General">
          <div class="columns ">
            <div class="column">
              <GeneralForm @save="persist" @submit="submitStep1"></GeneralForm>
            </div>
          </div>
        </b-tab-item>

        <b-tab-item v-if="useTab('Student Details')" label="Student Details">
          <div class="columns ">
            <div class="column">
              <LearnerForm @save="persist" @submit="submitStep2"></LearnerForm>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('Parent/Guardian 1')" label="Parent/Guardian 1">
          <div class="columns ">
            <div class="column">
              <GuardianForm :objectKey="'guardian1'" @save="persist" @submit="submitStep3"></GuardianForm>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('Parent/Guardian 2')" label="Parent/Guardian 2">
          <div class="columns ">
            <div class="column">
              <GuardianForm :can-skip="true" :objectKey="'guardian2'" @save="persist" @skip="skipGuardian2"
                            @submit="submitStep4"></GuardianForm>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('Siblings')" label="Siblings">
          <div class="columns ">
            <div class="column">
              <SiblingsForm @save="persist" @submit="submitStep5"></SiblingsForm>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('Medical')" label="Medical">
          <div class="columns ">
            <div class="column">
              <EmerMedicalForm @save="persist" @submit="submitStep6"></EmerMedicalForm>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('Payment Responsibility')" label="Payment Responsibility">
          <div class="columns ">
            <div class="column">
              <PaymentForm @save="persist" @submit="submitStep7"></PaymentForm>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('Other Info')" label="Other Info">
          <div class="columns ">
            <div class="column">
              <OtherForm @save="persist" @submit="submitStep8"></OtherForm>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('Package')" label="Package">
          <div class="columns ">
            <div class="column">
              <PackagesForm @save="persist" @submit="submitStep9"></PackagesForm>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('Additional Services')" label="Additional Services">
          <div class="columns ">
            <div class="column">
              <ServicesForm @save="persist" @submit="submitStep10"></ServicesForm>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('Ts & Cs')" label="Ts & Cs">
          <div class="columns ">
            <div class="column">
              <ConsentForm @submit="submitStep11"></ConsentForm>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </section>


  </div>

</template>

<script>

import User from "@/models/User"

import CampusHeader from "@/components/panelled-dash/CampusHeader";
import GeneralForm from "@/components/applications/g/GeneralForm";
import {mapState} from 'vuex';
import LearnerForm from "@/components/applications/g/LearnerForm";
import Lookup from "@/models/Lookup";
import Profile from "@/models/Profile";
import GuardianForm from "@/components/applications/g/GuardianForm";
import SiblingsForm from "@/components/applications/g/SiblingsForm";
import EmerMedicalForm from "@/components/applications/g/EmerMedicalForm";
import PaymentForm from "@/components/applications/g/PaymentForm";
import OtherForm from "@/components/applications/g/OtherForm";
import ConsentForm from "@/components/applications/g/ConsentForm";
import Application from "@/models/Application";
import {ApplyClient} from "@/clients/Register";
import Campus from "@/models/Campus";
import ServicesForm from "@/components/applications/g/ServicesForm";
import Service from "@/models/Service";
import PackagesForm from "@/components/applications/g/PackagesForm";
import Package from "@/models/Package";
import Note from "@/models/Note";

export default {
  name: 'GuardianApply',
  components: {
    ServicesForm,
    PackagesForm,
    ConsentForm,
    PaymentForm,
    EmerMedicalForm,
    CampusHeader,
    GeneralForm,
    LearnerForm,
    GuardianForm,
    SiblingsForm,
    OtherForm
  },
  data() {
    return {
      loaded: false,
      activeTab: 0,
      activatedTab: 0
    }
  },
  computed: {
    ...mapState('application', ['application']),
    tabs() {
      let data = []
      data = [...data, ...((this.activatedTab === 0 || this.application.general.saved) ? [{title: 'General'}] : [])]
      data = [...data, ...((this.activatedTab === 1 || this.application.student.saved) ? [{title: 'Student Details'}] : [])]
      data = [...data, ...((this.activatedTab === 2 || this.application.guardian1.saved) ? [{title: 'Parent/Guardian 1'}] : [])]
      data = [...data, ...((this.activatedTab === 3 || this.application.guardian2.saved) ? [{title: 'Parent/Guardian 2'}] : [])]
      data = [...data, ...((this.activatedTab === 4 || this.application.siblings.saved) ? [{title: 'Siblings'}] : [])]
      data = [...data, ...((this.activatedTab === 5 || this.application.emermedical.saved) ? [{title: 'Medical'}] : [])]
      data = [...data, ...((this.activatedTab === 6 || this.application.payment_responsibility.saved) ? [{title: 'Payment Responsibility'}] : [])]
      data = [...data, ...((this.activatedTab === 7 || this.application.other_info.saved) ? [{title: 'Other Info'}] : [])]
      data = [...data, ...((this.activatedTab === 8 || this.application.other_info.saved) ? [{title: 'Package'}] : [])]
      data = [...data, ...((this.activatedTab === 9 || this.application.other_info.saved) ? [{title: 'Additional Services'}] : [])]
      data = [...data, ...((this.activatedTab === 10 || this.application.other_info.saved) ? [{title: 'Ts & Cs'}] : [])]
      return data
    },
    headerText() {
      let text = ''
      switch (this.activeTab) {
        case 0:
          text = 'Step 1 of 11 - General'
          break;
        case 1:
          text = 'Step 2 of 11 - Student Details'
          break;
        case 2:
          text = 'Step 3 of 11 - First Parent\'s / Guardian\'s Details'
          break;
        case 3:
          text = 'Step 4 of 11 - Second Parent\'s / Guardian\'s Details'
          break;
        case 4:
          text = 'Step 5 of 11 - Siblings'
          break;
        case 5:
          text = 'Step 6 of 11 - Medical and Emergency Information'
          break;
        case 6:
          text = 'Step 7 of 11 - Payment Responsibility'
          break;
        case 7:
          text = `Step 8 of 11 - Other Information about the ${this.$tc('learner', 1)}`
          break;
        case 8:
          text = 'Step 9 of 11 - Package'
          break;
        case 9:
          text = 'Step 10 of 11 - Additional Services'
          break;
        case 10:
          text = 'Step 11 of 11 - Terms and Conditions'
      }
      return text
    },
    guardian() {
      return User.query().whereId(this.$store.state.user.id).first()
    }
  },
  methods: {
    useTab(title) {
      console.log(title)
      return this.tabs.some(tab => {
        return tab.title === title
      })
    },
    createLearner() {
      let storeUser = this.application.student.user

      if (storeUser.no_email === 1) {
        delete storeUser.email
      }
      if (this.application.student.id !== null) {
        this.updateLearner({...storeUser, ...{id: this.application.student.id}})
        return true
      }

      User.Store({...storeUser, ...{guardians: [this.$store.state.user.id]}}).then(({entities: {users}}) => {
        this.application.student.id = users[0].id
        Profile.Store(this.application.student.profile, users[0].id).then(() => {
          this.persist('student', false)

          this.$store.dispatch('loader/hide')
          this.changeTab(2)
        })
      }).catch(err => {
        this.$store.dispatch('loader/hide')
        this.handleError(err)
      })
    },
    updateLearner(user) {

      this.$store.dispatch('loader/show')

      this.application.student.id = user.id

      User.Update({...{id: user.id}, ...this.application.student.user, ...{guardians: [this.$store.state.user.id]}}, true).then(() => {
        Profile.FetchById(user.id).then(() => {
          Profile.Update({...this.application.student.profile, ...{user_id: user.id}}).then(() => {
            this.$store.dispatch('loader/hide')
            this.changeTab(2)

          })
        }).catch(err => {
          if (err.response.status === 404) {
            Profile.Store(this.application.student.profile, user.id).then(() => {
              this.$store.dispatch('loader/hide')
              this.changeTab(2)
            })
          } else {
            this.$store.dispatch('loader/hide')
            this.handleError(err)
          }
        })
      })
    },
    skipGuardian2() {
      this.persist('guardian2', false)
      this.changeTab(4)
    },
    submitStep1() {
      this.persist('general', false)
      this.changeTab(1)
    },
    submitStep2() {

      this.application.student.user.campuses = [this.application.general.campus_id]
      this.persist('student', false)
      this.$store.dispatch('loader/show')
      let campusUser = User.query().whereId(this.$store.state.user.id).first()
      delete campusUser.roles
      let user_campuses = !campusUser.campus_ids.includes(this.application.general.campus_id) ?
          User.Update({
            ...campusUser, ...{
              campuses: [...campusUser.campus_ids, ...[this.application.general.campus_id]],
            }
          }, true) : Promise.resolve()
      Promise.all([user_campuses]).then(() => {
        if (this.application.student.user.no_email !== 1) {
          User.FetchAll({page: 1, limit: 15}, {
            search: this.application.student.user.email,
            user_role: 'learner'
          }).then(({entities}) => {
            if (entities.users) {
              const user = entities.users[0]

              this.$store.dispatch('loader/hide')
              Application.FetchAll({page: 1, limit: 15}, {user_id: user.id}).then(({entities}) => {
                if (entities.applications) {
                  if (entities.applications.some(item => item.victory_status_id === 1)) {
                    this.$buefy.dialog.alert(`There is already an application for the ${this.$tc('learner', 1)} using this email address, ${user.first_name} ${user.last_name},  and a unique email address is required for each learner application. Please select "Has No Email" if you do not have an alternative email address.`)
                  } else {
                    this.$buefy.dialog.confirm({
                      message: `There is already a ${this.$tc('learner', 1)} profile with the name ${user.first_name} ${user.last_name} using the email address given. If this is the student you wish to apply for please click Continue. If not please click Cancel and either change the email address or select "Has No Email".`
                      ,
                      confirmText: 'Continue',
                      onConfirm: () => this.updateLearner(user),
                      type: 'is-primary'
                      , cancelText: 'Cancel',
                      canCancel: ['button', 'outside']

                    })
                  }
                } else {
                  this.$buefy.dialog.confirm({
                    message: `There is already a student profile with the name ${user.first_name} ${user.last_name} using the email address given. If this is the student you wish to apply for please click Continue. If not please click Cancel and either change the email address or select "Has No Email".`
                    ,
                    confirmText: 'Continue',
                    onConfirm: () => this.updateLearner(user),
                    type: 'is-primary'
                    , cancelText: 'Cancel',
                    canCancel: ['button', 'outside']

                  })
                }
              })

            } else {

              this.createLearner()
            }
          })
        } else {
          this.createLearner()
        }

      })
    },
    submitStep3() {

      this.persist('guardian1', false)
      this.$store.dispatch('loader/show')
      let campusUser = User.query().whereId(this.$store.state.user.id).first()
      delete campusUser.roles
      let user_campuses = !campusUser.campus_ids.includes(this.application.general.campus_id) ?
          User.Update({
            ...campusUser, ...{
              campuses: [...campusUser.campus_ids, ...[this.application.general.campus_id]],
            }
          }, true) : Promise.resolve()

      Promise.all([user_campuses]).then(() => {

        User.FetchAll({page: 1, limit: 15}, {
          search: this.application.guardian1.user.email,
          user_role: 'guardian'
        }, ['campuses', 'wards']).then(({entities}) => {
          if (entities.users) {
            const user = User.query().where('email', this.application.guardian1.user.email).first()
            user.first_name = this.application.guardian1.user.first_name
            user.last_name = this.application.guardian1.user.last_name
            user.title = this.application.guardian1.user.title
            user.primary_contact_number = this.application.guardian1.user.primary_contact_number
            user.secondary_contact_number = this.application.guardian1.user.secondary_contact_number
            user.cellphone_number = this.application.guardian1.user.cellphone_number


            delete user.roles
            User.Update({
              ...user, ...{wards: [this.application.student.id, ...user.ward_ids]}, ...{
                campuses: [...user.campus_ids, ...[this.application.general.campus_id]],
              }
            }, true).then(() => {
              Profile.FetchById(user.id).then(() => {
                Profile.Update({...this.application.guardian1.profile, ...{user_id: user.id}}).then(() => {
                  this.$store.dispatch('loader/hide')
                  this.changeTab(3)

                })
              }).catch(err => {
                if (err.response.status === 404) {
                  Profile.Store(this.application.guardian1.profile, user.id).then(() => {
                    this.$store.dispatch('loader/hide')
                    this.changeTab(3)
                  })
                } else {
                  this.$store.dispatch('loader/hide')
                  this.handleError(err)
                }
              })
            })
          } else {
            User.Store({...this.application.guardian1.user, ...{wards: [this.application.student.id]}}).then(({entities: {users}}) => {
              Profile.Store(this.application.guardian1.profile, users[0].id).then(() => {
                this.$store.dispatch('loader/hide')
                this.changeTab(3)
              }).catch(err => {
                this.$store.dispatch('loader/hide')
                this.handleError(err)
              })
            })
          }
        })
      })

    },
    submitStep4() {
      this.persist('guardian2', false)
      this.$store.dispatch('loader/show')


      User.FetchAll({page: 1, limit: 15}, {
        search: this.application.guardian2.user.email,
        user_role: 'guardian',
        order_by: 'first_name'
      }, ['campuses', 'wards']).then(({entities}) => {
        if (entities.users) {
          const user = User.query().where('email', this.application.guardian2.user.email).first()
          delete user.roles
          user.first_name = this.application.guardian1.user.first_name
          user.last_name = this.application.guardian1.user.last_name
          user.title = this.application.guardian1.user.title
          user.primary_contact_number = this.application.guardian1.user.primary_contact_number
          user.secondary_contact_number = this.application.guardian1.user.secondary_contact_number
          user.cellphone_number = this.application.guardian1.user.cellphone_number


          User.Update({
            ...user, ...{wards: [this.application.student.id, ...user.ward_ids]}, ...{
              campuses: [...user.campus_ids, ...[this.application.general.campus_id]],
            }
          }, true).then(() => {
            Profile.FetchById(user.id).then(() => {
              Profile.Update({...this.application.guardian2.profile, ...{user_id: user.id}}).then(() => {
                this.$store.dispatch('loader/hide')
                this.changeTab(4)

              })
            }).catch(err => {
              if (err.response.status === 404) {
                Profile.Store(this.application.guardian2.profile, user.id).then(() => {
                  this.$store.dispatch('loader/hide')
                  this.changeTab(4)
                })
              } else {
                this.handleError(err)

              }
            })
          })
        } else {
          User.Store({
            ...this.application.guardian2.user, ...{wards: [this.application.student.id]}, ...{
              campuses: [...User.query().whereId(this.$store.state.user.id).first().campus_ids, ...[this.application.general.campus_id]],
            }
          }).then(({entities: {users}}) => {
            Profile.Store(this.application.guardian2.profile, users[0].id).then(() => {
              this.$store.dispatch('loader/hide')
              this.changeTab(4)
            })
          }).catch((err) => {
            this.$store.dispatch('loader/hide')
            this.handleError(err)

            this.loading = false;
          });
        }
      })


    },
    submitStep5() {
      this.persist('siblings', false)
      this.$store.dispatch('loader/show')
      Profile.Update({...this.application.student.profile, ...{user_id: this.application.student.id}}).then(() => {
        this.$store.dispatch('loader/hide')
        this.changeTab(5)

      }).catch((err) => {
        this.$store.dispatch('loader/hide')
        this.handleError(err)
        this.loading = false;
      });

    },
    submitStep6() {
      this.persist('emermedical', false)
      this.$store.dispatch('loader/show')
      Profile.Update({...this.application.student.profile, ...{user_id: this.application.student.id}}).then(() => {
        this.$store.dispatch('loader/hide')
        this.changeTab(6)

      }).catch((err) => {
        this.$store.dispatch('loader/hide')
        this.handleError(err)
        this.loading = false;
      });

    },
    submitStep7() {
      this.persist('payment_responsibility', false)
      this.changeTab(7)
    }, submitStep8() {
      this.persist('other_info', false)
      this.$store.dispatch('loader/show')
      Profile.Update({...this.application.student.profile, ...{user_id: this.application.student.id}}).then(() => {
        this.$store.dispatch('loader/hide')
        this.changeTab(8)

      }).catch((err) => {
        this.$store.dispatch('loader/hide')
        this.handleError(err)
        this.loading = false;
      });

    }, finish() {
      localStorage.clear()
      this.$store.dispatch('application/clearApplication').then(() => {
        localStorage.clear()
        this.$router.push('/g/applications')

      })
      this.$router.push('/g/applications')

    }, submitStep9() {
      this.persist('services', false)

      this.changeTab(9)

    }, submitStep10() {
      this.persist('services', false)
      let servicesString = ''
      this.application.services.optionalServices.map(service => {
        if (!this.application.services.package_services.includes(service)) {
          servicesString += `${servicesString.length === 0 ? '' : ','} ID:${service} Name:${Service.query().where('id', service).first().name}`
        }
      })


      this.application.services.noteString = `This applicant would like to sign up for the following package: ID:${this.application.services.package_id} Name:${Package.query().where('id', this.application.services.package_id).first().name} ${servicesString.length > 0 ? `and the following service(s):` : '.'} ${servicesString}${servicesString.length > 0 ? '.' : ''}`
      this.changeTab(10)
    },
    submitStep11() {
      Application.deleteAll()
      this.$store.dispatch('loader/show')
      Promise.all([Profile.Update({...this.application.student.profile, ...{user_id: this.application.student.id}}), Application.Store({...this.application.general, ...{user_id: this.application.student.id}, ...{status_id: 99}})]).then(() => {
        Promise.all([
          ...[Note.Store({note: this.application.services.noteString}, {
            type: 'applications',
            id: Application.query().first().id
          })],
          ...(this.application.general.programme_id !== null ? [Note.Store({note: `The applicant would to do the programme ${this.application.general.programme_delivery_type === 'remote' ? 'remotely' : 'on campus'} and would like to pay ${this.application.general.programme_payment_type === 'monthly' ? 'monthly' : 'annually'}.The applicant ${this.application.general.has_device === 1 ? 'has' : 'does not have'} a device and would ${this.application.general.purchase_device === 1 ? '' : 'not'} like to purchase one.`}, {
            type: 'applications',
            id: Application.query().first().id
          })] : [])
        ]).then(() => {
          this.$store.dispatch('loader/hide')
          this.$buefy.dialog.alert({
            title: `Application Sent`,
            confirmText: `Ok`,
            type: "is-primary",
            message: `<p>Thank you for your application to Generation Schools.</p><br>
<p>We have received your application, and are busy processing it. One of our dedicated team members will be in contact with you shortly to confirm your application and explain the admissions process.</p>`,
            onConfirm: () =>
                this.finish()
          });


        })

      }).catch((err) => {
        this.$store.dispatch('loader/hide')
        this.handleError(err)
        this.loading = false;
      });
    },
    persist(key, notification = true) {
      this.application[key].saved = true
      localStorage.setItem(`applications_data_06_07_2021`, JSON.stringify(this.application))
      if (notification) {
        this.$buefy.snackbar.open('Saved!')
      }
    },
    changeTab(tab) {
      this.activatedTab = tab
      this.$nextTick(() => {
        this.activeTab = tab
      })
    }
  }
  ,
  mounted() {
    this.$buefy.dialog.confirm({
      message: `This section applies to ${this.$tc('learner', 2)} who are not yet enrolled with Generation Schools. Please select <a class="has-text-link" href="/g/my-wards" >"My Learners"</a> if you'd like to update the details of one of our current learners.`
      ,
      confirmText: 'Make a new Application',
      type: 'is-primary',
      onCancel: () => this.$router.push('/g/my-wards'),
      onConfirm: () => {
        if (this.application.student.id !== null) {
          this.$buefy.dialog.confirm({
            message: `There is a saved application for ${this.application.student.user.first_name} ${this.application.student.user.last_name}. Would you like to continue with it, or start a new application?`
            ,
            confirmText: 'Resume Application',
            type: 'is-primary',
            onCancel: () => {
              this.$store.dispatch("application/clearApplication");
              this.application.guardian1.user.first_name = this.$store.state.user.first_name
              this.application.guardian1.user.last_name = this.$store.state.user.last_name
              this.application.guardian1.user.email = User.query().find(this.$store.state.user.id).email
            }
            , cancelText: 'Create New',
            canCancel: ['button']

          })
        }
      }
      , cancelText: `Go to My ${this.$tc('Learner', 2)}`,
      canCancel: ['button']

    })


    ApplyClient.viewCampuses()
        .then((result) => {
              Campus.insertOrUpdate({
                data: result.data.data.map(campus => {
                  let {attributes: {phases}} = campus
                  phases.map(phase => {
                    let {attributes: {stages}} = phase
                    stages.map(stage => {
                      Object.assign(stage, stage.attributes)
                    })
                    Object.assign(phase, phase.attributes)
                  })
                  return {...campus, ...campus.attributes}
                })

              })
            }
        )
    let userGet = User.FetchById(this.$store.state.user.id, ['wards', 'campuses']).then(() => {
      this.loaded = true
    })

    Promise.all([userGet, Lookup.FetchAll({page: 1, limit: 9999})]).then(() => {
      this.loaded = true
      this.application.guardian1.user.first_name = this.$store.state.user.first_name
      this.application.guardian1.user.last_name = this.$store.state.user.last_name
      this.application.guardian1.user.email = User.query().find(this.$store.state.user.id).email
    })
  }
}


</script>
